import { Link } from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import PrintingComponent from "../../components/printingComponent";
import CommitmentParticipation from "./CommitmentParticipation";
import DeclarationAffiliation from "./DeclarationAffiliation";
import {MainContext} from "../../context/MainContext";

export default function ListingAthletes() {
  const {setPageTitle, setLoading} = useContext(MainContext)
  const [athletes, setAthletes] = useState<any>([])
  const [impressao, setImpressao] = useState<any>({})

  const getAthletes = async () => {
    await api.get('/api/athletes').then((response) => {
      const data = response.data
      setAthletes(data)
    })
  }

  const childToParent = (childdata:any) => {
    setImpressao({imprimir:false, athlete:null, tipo:null})
  }

  useEffect(() => {
    setPageTitle("Sócios")
    setLoading(true)

    const fetchData = async () => {
      await getAthletes()
    };

    fetchData().then(setLoading(false))
  }, [])

  const columns: any = [
    {
      name: 'member_number', label: 'N. sócio', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'full_name', label: 'Nome', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'birth_date_formated', label: 'Data nasc', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phone', label: 'TELEFONE', options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: '', label: '', options: {
        filter: false,
        false: true,
        customBodyRenderLite: (index: string | number) => {return (
            <>
              <Link className='btn btn-sm btn-warning me-2' to={`/members/update/${athletes[index]?.id}`} title="Editar">
                <i className="bi bi-pencil" aria-hidden="true"></i>
              </Link>

              <button id="btnPrinter" type="button" className="btn btn-sm btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-printer"></i></button>
              <ul className="dropdown-menu" aria-labelledby="btnPrinter">
                <li><a className="dropdown-item" href="#" onClick={() => setImpressao({imprimir:true, athlete:athletes[index]?.id, tipo:'DeclarationAffiliation'})}>Declaração De Filiação</a></li>
                <li><a className="dropdown-item" href="#" onClick={() => setImpressao({imprimir:true, athlete:athletes[index]?.id, tipo:'CommitmentParticipation'})}>Compromisso Em Participações</a></li>
              </ul>
            </>
        )
        },
      },
    },
  ]

  const options: any = DataTableOptions

  return (
      <>
        <div className='mb-4'>
          <Link className='btn btn-success mb-3' to='/members/create'><i className="bi bi-plus-circle">&nbsp;</i> Cadastrar Sócio</Link>

          <MUIDataTable
              title={''}
              data={athletes}
              columns={columns}
              options={options}
          />
        </div>

        {impressao.imprimir &&
          <PrintingComponent componentToPrint={impressao.tipo === "CommitmentParticipation" ? <CommitmentParticipation athleteId={impressao.athlete}/> : <DeclarationAffiliation athleteId={impressao.athlete}/>} textButon={""} childToParent={childToParent} />
        }
      </>
  )
}
